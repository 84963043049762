@use 'src/src/app/scss/abstracts' as *;

.feature-card {
  $block-name: &;

  //   &#{$block-name} {
  //   }
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;

  &:hover {
    #{$block-name} {
      &__inner {
        transform: translate3d(0, 0, 0) scale(0.96);

        @include mq($until: md) {
          transform: translate3d(0, 0, 0) scale(1);
        }
      }

      &__title {
        transform: translate3d(0, -50%, 0);

        @include mq($until: md) {
          transform: translate3d(0, 0, 0);
        }
      }

      &__description {
        transform: translate3d(0, -65%, 0);
        opacity: 1;

        @include mq($until: md) {
          transform: translate3d(0, 0, 0);
        }
      }
    }
  }

  &__inner {
    flex: 1 1 auto;
    padding: 40px;
    padding-bottom: 30px;
    overflow: hidden;
    box-shadow: 0 2px 54px 0 rgb(118 69 255 / 40%);
    border-radius: $border-radius-s;
    background-color: $color-base-black-100;
    transition: $transition-slow;

    @include mq($until: md) {
      box-shadow: 0 1.022px 27.603px 0 rgb(118 69 255 / 40%);
      pointer-events: none;
      padding: 20px;
    }
  }

  &__description {
    color: $color-base-gray-500;
    margin-top: 8px;
    transform: translate3d(0, 0, 0);
    transition:
      transform $transition-slow,
      opacity $transition-slow;
    opacity: 0;

    @include mq($until: md) {
      opacity: 1;
      margin-top: 5px;
    }
  }

  &__media {
    border-radius: $border-radius-s;
    overflow: hidden;

    .video {
      &__inner {
        margin: -2px;
      }
    }
  }

  &__text-box {
    margin-top: 44px;

    @include mq($until: md) {
      margin-top: 24px;
    }
  }

  &__title {
    color: $color-base-purple-200;
    transform: translate3d(0, 0, 0);
    transition: transform $transition-slow;

    .title {
      @include mq($until: md) {
        font-size: 24px;
      }
    }
  }

  &--loading {
    pointer-events: none;

    #{$block-name} {
      &__inner {
        @include skeleton;

        box-shadow: none;
      }

      &__media {
        opacity: 0;
      }

      &__text-box {
        opacity: 0;
      }
    }
  }
}

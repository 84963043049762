@use 'src/src/app/scss/abstracts' as *;

.game-frame-skeleton {
  @include skeleton;

  border-radius: $border-radius-s;
}

.game-frame {
  $block-name: &;

  //   &#{$block-name} {
  //   }

  &__inner {
    @include skeleton;

    display: block;
    position: relative;
    z-index: 1;
    overflow: hidden;
    border-radius: $border-radius-s;
    transform: translate3d(0, 0, 0);

    @include mq($until: md) {
      border-radius: $border-radius-xs;
    }

    @include mq($until: sm) {
      margin-left: -4px;
      margin-right: -4px;
    }
  }

  &__ig,
  &__media {
    @include position(absolute, 0 0 0 0);

    width: 100%;
    height: 100%;
  }

  &__media {
    display: block;
    z-index: 1;

    &:hover {
      #{$block-name} {
        &__button {
          opacity: 1;
        }
      }
    }

    &:active {
      #{$block-name} {
        &__button {
          transform: translate3d(-50%, -50%, 0) scale(0.96);
        }
      }
    }
  }

  &__ig {
    z-index: 3;
  }

  &__button {
    position: absolute;
    z-index: 2;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate3d(-50%, -50%, 0) scale(1);
    transition:
      opacity $transition-slow,
      transform $transition-fast;

    @media (hover: none) and (pointer: coarse) {
      opacity: 1;
    }
  }

  &__close-game-button {
    @include reset-text-indents;

    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    z-index: 1;
    top: 9px;
    right: 9px;
    width: 38px;
    height: 38px;
    color: rgb(214 208 231 / 100%);
    background-color: rgb(10 6 20 / 20%);
    border-radius: 6px;
    transition: color $transition-fast;

    &:hover {
      color: rgb(255 255 255 / 100%);
    }

    &:active {
      color: rgb(214 208 231 / 100%);
    }
  }
}
